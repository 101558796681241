import React from 'react';
import SimpleLocalize from "../components/SimpleLocalize/SimpleLocalize";
import MainLayout from "../components/Layouts/MainLayout/MainLayout";
import ProjectsSection from "../components/Sections/ProjectsSection/ProjectsSection";
import BasicInfoSection from "../components/Sections/BasicInfoSection/BasicInfoSection";

const HomePage = (props) => {
    return (
        <>
            <SimpleLocalize {...props}>
                <MainLayout
                    currentLang={props.pageContext.language}
                    title=""
                    description={props.pageContext.messages['index_desc']}
                    locationProps={props.location}
                    navigate={props.navigate}
                >
                    <BasicInfoSection />
                    <ProjectsSection
                        locationProps={props.location}
                    />
                </MainLayout>
            </SimpleLocalize>
        </>
    );

}

export default HomePage;
