import React, { useState, useLayoutEffect, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import AnimateHeight from "react-animate-height";
import { aboutInfo } from "../../../constants/constants";
import { useWindowResize } from "../../../services/hooks";

import "./BasicInfoSection.scss";

const BasicInfoSection = () => {
    const { windowWidth, windowHeight } = useWindowResize();
    const [ elementHeight, setElementHeight ] = useState(0);

    const [selected, setSelected] = useState('about');
    const [openedInfos, setOpenedInfos] = useState(
        () => {
            const array = new Array(3).fill(false);
            array[0] = true;
            return array;
        });

    let lastScroll = 0;
    useEffect(() => {
        setElementHeight(document.querySelector(".basic-info-wrapper")?.getBoundingClientRect().height || 0);
    }, [windowWidth, windowHeight]);

    const onUpScroll = () => {
        const infoOuterWrapper = document.querySelector(".basic-info-outer-wrapper");
        const infoWrapperHeight = infoOuterWrapper.getBoundingClientRect().height;
        const infoWrapperScroll = Math.max(window.scrollY - infoOuterWrapper.offsetTop, 0);

        // console.log(infoWrapperScroll);
        // downward scroll
        const scrollLengthInVh = 50; // this is how long each section is scrolled for - make sure this is equal to $scrollLength in scss
        const infoWrapperHeightInVh = (3 * scrollLengthInVh + 100) // we add 100vh so the last section is visible for the duration of its scrollLength
        const scrollOnEach = infoWrapperHeight / (infoWrapperHeightInVh / scrollLengthInVh);

        if (infoWrapperScroll > lastScroll) {
            if ((infoWrapperScroll > (scrollOnEach)) && !openedInfos[1] && openedInfos[0]) {
                setSelected('mission');
                openedInfos[1] = true;
                setOpenedInfos([...openedInfos]);
                lastScroll = infoWrapperScroll;
                return
            } else if ((infoWrapperScroll > (scrollOnEach * 2)) && !openedInfos[2] && openedInfos[1]) {
                setSelected('vision');
                openedInfos[2] = true;
                setOpenedInfos([...openedInfos]);
                lastScroll = infoWrapperScroll;
                return
            }
        }
        else {
            if ((infoWrapperScroll < (scrollOnEach * 2)) && openedInfos[2]) {
                setSelected('mission');
                openedInfos[2] = false;
                setOpenedInfos([...openedInfos]);
                lastScroll = infoWrapperScroll;
                return
            } else if ((infoWrapperScroll < (scrollOnEach)) && openedInfos[1]) {
                setSelected('about');
                openedInfos[1] = false;
                setOpenedInfos([...openedInfos]);
                lastScroll = infoWrapperScroll;
                return
            }
        }
    }
    const handleOpenClick = (i) => {
        openedInfos[i] = !openedInfos[i];
        setOpenedInfos([...openedInfos]);
    }

    useLayoutEffect(() => {
        if (windowWidth > 850) {
            window.addEventListener("scroll", onUpScroll);
        }
        return windowWidth > 850 ? () => {
            window.removeEventListener("scroll", onUpScroll);
        } : () => {}
    }, []);

    const handleClick = (i) => {
        const infoOuterWrapper = document.querySelector(".basic-info-outer-wrapper");
        const infoWrapperHeight = infoOuterWrapper.getBoundingClientRect().height;

        const scrollLengthInVh = 50; // this is how long each section is scrolled for - make sure this is equal to $scrollLength in scss
        const infoWrapperHeightInVh = (3 * scrollLengthInVh + 100) // we add 100vh so the last section is visible for the duration of its scrollLength
        const scrollOnEach = infoWrapperHeight / (infoWrapperHeightInVh / scrollLengthInVh);

        window !== undefined && window.scroll({
            top: ((scrollOnEach * i) + infoOuterWrapper.offsetTop + 10),
            behavior: "smooth",
        });
    }

    return (
        <div className="basic-info-outer-wrapper" style={{ '--height': `${elementHeight}px` }}>
            <div id="about_us" className="basic-info-wrapper center padding content header-margin">
                <h2 className="title">
                    <FormattedMessage
                        id="improve_the_world"
                    />
                </h2>
                <div className="info-wrapper">
                    <div className="info-titles">
                        <h3
                            className={`info-title ${openedInfos[0] && 'opened'} ${selected === 'about' && 'selected'}`}
                            id="about"
                            onClick={() => {
                                handleClick(0);
                            }}
                        >
                            <FormattedMessage
                                id="about_us"
                            />
                        </h3>
                        <h3
                            className={`info-title ${openedInfos[1] && 'opened'} ${selected === 'mission' && 'selected'}`}
                            id="mission"
                            onClick={() => {
                                handleClick(1);
                            }}
                        >
                            <FormattedMessage
                                id="our_mission"
                            />
                        </h3>
                        <h3
                            className={`info-title ${openedInfos[2] && 'opened'} ${selected === 'vision' && 'selected'}`}
                            id="vision"
                            onClick={() => {
                                handleClick(2);
                            }}
                        >
                            <FormattedMessage
                                id="our_vision"
                            />
                        </h3>
                    </div>
                    <div className="info-descriptions">
                        <p
                            className={`info-description ${selected === 'about' && 'opened'}`}
                        >
                            <>
                                <FormattedMessage
                                    id="about_us_desc1"
                                />
                                <br />
                                <br />
                                <FormattedMessage
                                    id="about_us_desc2"
                                />
                            </>
                        </p>
                        <p className={`info-description ${selected === 'mission' && 'opened'}`}>
                            <>
                                <FormattedMessage
                                    id="our_mission_desc1"
                                />
                                <br />
                                <br />
                                <FormattedMessage
                                    id="our_mission_desc2"
                                />
                            </>
                        </p>
                        <p className={`info-description ${selected === 'vision' && 'opened'}`}>
                            <>
                                <FormattedMessage
                                    id="our_vision_desc1"
                                />
                                <br />
                                <br />
                                <FormattedMessage
                                    id="our_vision_desc2"
                                />
                                <br />
                                <br />
                                <FormattedMessage
                                    id="our_vision_desc3"
                                />
                            </>
                        </p>
                    </div>
                </div>
                <div className="info-wrapper-mobile">
                    {aboutInfo.map((info, i) => (
                        <div className="single-info-wrapper" key={info.name} >
                            <div className={`info ${openedInfos[i] && 'opened'}`} onClick={() => handleOpenClick(i)}>
                                <div className="info-title">
                                    <h6>
                                        <FormattedMessage
                                            id={info.name}
                                        />
                                    </h6>
                                </div>
                                    <div
                                        className={`caret ${openedInfos[i] && 'opened'}`}
                                    >
                                        <img
                                            className="caret-image"
                                            src="/caret.svg"
                                            alt="caret"
                                            width={33}
                                            height={17}
                                        />
                                    </div>
                            </div>
                                <AnimateHeight duration={300} height={openedInfos[i] ? 'auto' : 0}>
                                    <div className="info-descriptions">
                                        <p>
                                            <FormattedMessage
                                                id={info.desc1}
                                            />
                                            <br />
                                            <br />
                                            <FormattedMessage
                                                id={info.desc2}
                                            />
                                            {info.desc3 && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <FormattedMessage
                                                        id={info.desc3}
                                                    />
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </AnimateHeight>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default BasicInfoSection;
