import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StaticImage } from "gatsby-plugin-image"

import './ProjectsSection.scss';
import { useWindowResize } from "../../../services/hooks";
import LinkTranslated from "../../LinkTranslated/LinkTranslated";

const ProjectsSection = ({ locationProps }) => {
    const { windowWidth } = useWindowResize();
    return (
        <div className="projects-section-wrapper" id="projects">
            <div className="subsection-title-wrapper content center">
                <h3 className="subsection-title">
                    <FormattedMessage
                        id="born_on_eth"
                    />
                </h3>
            </div>
            <div className="defisaver-section-wrapper">
                <div className="content center padding defisaver-section">
                    <div className="left">
                        <h2 className="project-title">
                            DeFi Saver
                        </h2>
                        <p className="project-subtitle">
                            <FormattedMessage
                                id="defisaver_subtitle"
                            />
                        </p>
                        <a
                            href="https://defisaver.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button
                                className="defisaver-button"
                            >
                                <FormattedMessage
                                    id="visit_website"
                                />
                            </button>
                        </a>
                    </div>
                    <div className="right">
                        <div className="defisaver-image">
                            {windowWidth < 1150
                                ? (<StaticImage
                                    src="../../../images/defisaver-img2.png"
                                    alt="defisaver"
                                    placeholder="blurred"
                                    quality={80}
                                    layout="constrained"
                                />)
                                : (<StaticImage
                                src="../../../images/defisaver-img.png"
                                alt="defisaver"
                                placeholder="blurred"
                                quality={80}
                                layout="constrained"
                                />)
                            }
                        </div>
                        <div className="project-description">
                            <FormattedMessage
                                id="defisaver_desc"
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="other-projects-section content center padding">
                <div className="defiexplore-section">
                    <StaticImage
                        src="../../../images/defiexplore-img.jpg"
                        alt="defiexplore"
                        placeholder="blurred"
                        quality={80}
                        layout="constrained"
                    />
                    <h2 className="project-title">
                        DeFi Explore
                    </h2>
                    <div className="project-description">
                        <FormattedMessage
                            id="defiexplore_desc"
                        />
                    </div>
                    <a
                        href="https://defiexplore.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FormattedMessage
                            id="website"
                        />
                        <img
                            src="/new-window-icon.svg"
                            alt="New Window"
                            className="new-window-icon"
                        />
                    </a>
                </div>
                <div className="cryptographics-section">
                    <StaticImage
                        src="../../../images/cryptographics-img.jpg"
                        alt="cryptographics"
                        placeholder="blurred"
                        quality={80}
                        layout="constrained"
                    />
                    <h2 className="project-title">
                        Cryptographics
                    </h2>
                    <div className="project-description">
                        <FormattedMessage
                            id="cryptographics_desc"
                        />
                    </div>
                    <a
                        href="https://cryptographics.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FormattedMessage
                            id="website"
                        />
                        <img
                            src="/new-window-icon.svg"
                            alt="New Window"
                            className="new-window-icon"
                        />
                    </a>
                </div>
                <div className="cryptage-section">
                    <StaticImage
                        src="../../../images/cryptage-img.jpg"
                        alt="cryptage"
                        placeholder="blurred"
                        quality={80}
                        layout="constrained"
                    />
                    <h2 className="project-title">
                        Cryptage
                    </h2>
                    <div className="project-description">
                        <FormattedMessage
                            id="cryptage_desc"
                        />
                    </div>
                    <a
                        href="https://cryptage.co/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FormattedMessage
                            id="website"
                        />
                        <img
                            src="/new-window-icon.svg"
                            alt="New Window"
                            className="new-window-icon"
                        />
                    </a>
                </div>
            </div>
            <div className="join-forces-section-wrapper">
                <div className="join-forces-section center padding content">
                    <h2 className="title">
                        <FormattedMessage
                            id="your_turn"
                        />
                    </h2>
                    <h2 className="title">
                        <FormattedMessage
                            id="await"
                        />
                    </h2>
                    <LinkTranslated
                        href="/careers/"
                        pathname={locationProps.pathname}
                    >
                        <button
                            className="action-button shine"
                            type="button"
                            // onClick={handleSendClick}
                        >
                            <FormattedMessage
                                id="join_forces"
                            />
                        </button>
                    </LinkTranslated>
                </div>
            </div>
        </div>
    )
}

export default ProjectsSection;